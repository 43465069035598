import Vue from 'vue'

import { required } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

extend('required', {
  ...required,
  message: 'Fill out this field'
})

setInteractionMode('eager')

export default Vue.mixin({
  data () {
    return {
      fullNameRules: [
        v => !!v || this.$t('validationRequired'),
        v => (v && v.length <= 40) || 'Full name must be less than 40 characters'
      ],
      nameRules: [
        v => !!v || this.$t('validationRequired'),
        v => (v && v.length <= 40) || 'Full name must be less than 40 characters'
      ],
      defaultRules: [
        v => !!v || this.$t('validationRequired')
      ],
      keyRules: [
        v => !!v || this.$t('validationRequired'),
        v => (v && v.length <= 30) || 'The key must be no more than 30 characters long',
        v => (v && v.length >= 3) || 'The key must be at least 3 characters long'
      ],
      valueRules: [
        v => (v && v.length <= 100) || 'The value must be no more than 100 characters long'
      ],
      providerRules: [
        v => !!v || this.$t('validationRequired'),
        v => (v && v.length <= 30) || 'The field must be no more than 30 characters long',
        v => (v && v.length >= 3) || 'The field must be at least 3 characters long'
      ],
      campaignRules: [
        v => (v && v.length <= 15) || 'The value must be no more than 15 characters long'
      ],
      emailRules: [
        v => !!v || this.$t('validationRequired'),
        v => /.+@.+\..+/.test(v) || this.$t('validationEmail')
      ],
      countryRules: [
        v => !!v || this.$t('validationRequired')
      ],
      rolesRules: [
        v => !!v || this.$t('validationRequired')
      ],
      required: [
        v => !!v || this.$t('validationRequired')
      ],
      sortRules: [
        v => !!v || this.$t('validationRequired')
        // v => (v && v.length <= 6) || 'Position must be less than 6 characters'
      ],
      phoneRules: [
        v => !!v || this.$t('validationRequired')
      ],
      interfaceRules: [
        v => !!v || this.$t('validationRequired')
      ],
      activityRules: [
        v => !!v || this.$t('validationRequired')
      ],
      birthdayRules: [
        v => !!v || this.$t('validationRequired')
      ],
      genderRules: [
        v => !!v || this.$t('validationRequired')
      ],
      lengthFourLength: [
        v => (v && v.length >= 5) || 'Position must be less than 4 characters'
      ],
      coursesRules: [
        v => !!v || this.$t('validationRequired')
      ],
      planRules: [
        v => !!v || this.$t('validationRequired')
      ],
      passwordRules: [
        v => !!v || this.$t('validationRequired'),
        v => (v && v.length <= 50) || 'Password must be less than 50 characters',
        v => (v && v.length >= 10) || 'Password must be less than 10 characters'
      ],
      confirmPasswordRules: [
        v => !!v || this.$t('validationRequired'),
        v => (v === this.payload.password) || this.$t('validationSameAs')
      ],
      urlRules: [
        v => !!v || this.$t('validationRequired')
      ]
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider
  }
})
